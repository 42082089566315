<template>
	<div class="products--filters">
		<div class="filters--cover" @click.prevent="closeFilters"></div>
		<div class="filters--wrapper">
			<div class="cont">
				<div class="button" @click.prevent="closeFilters">
					<span class="link--mouse-small">Chiudi</span>
				</div>
				<div class="list" v-if="filters">
					<div class="list--block" v-for="(filter, index) in filters" :key="index">
						<h2>{{filter.title}}</h2>
						<ul>
							<li v-for="el in filter.data" :key="el.term_id" :data-tax="el.taxonomy" :data-term="el.term_id" :data-slug="el.slug" @click.prevent="toggleThisFilter($event)">
								<span>{{el.name}}</span>
								<div class="check"></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import gsap from 'gsap';
import axios from 'axios';
export default {
	data() {
    return {
      filters: [],
			taxonomies: {"genere": { "title": this.$t('prodotti.filtri.genere')}, "fondo": { "title": this.$t('prodotti.filtri.fondo')}, "lavorazione": { "title": this.$t('prodotti.filtri.lavorazione')}},
			post_type: 'prodotto',
			selectedFilters: {},
			delay: 2000
    };
  },
	props: {
		isOpen: {
			type: Boolean,
			required: true
		},
		finishFilter: {
			type: Boolean
		}
	},
	watch: {
		isOpen: {
			handler() {
        this.openFilters();
      }
		},
		finishFilter: {
			handler() {
				if(this.finishFilter){
					this.closeFilters();
				}
      }
		}
	},
	methods: {
		toggleThisFilter(event) {
			let run;
			clearTimeout(run);
			let total = 0;
			this.selectedFilters = {"genere": {}, "fondo": {}, "lavorazione": {}};
			let el = event.currentTarget;
			el.querySelector('.check').classList.toggle('active');
			let els = document.querySelectorAll('.list--block');
			//let i = 1;
			[...els].forEach((block) => {
				let els = block.querySelectorAll('ul li');
				let arr = [];
				let tax = '';
				[...els].forEach((li) => {
					tax = li.getAttribute('data-tax');
					if(li.querySelector('.check').classList.contains('active')){
						total++;
						arr.push(li.getAttribute('data-term'))
					}
				});
				this.selectedFilters[tax] = arr;
			});
			let that = this;
			if(total != 0){
				run = setTimeout(() => {
					that.$emit('filtered', that.selectedFilters)
				}, that.delay);
			}else{
				run = setTimeout(() => {
					that.$emit('filtered', false)
				}, that.delay);
			}
		},
		closeFilters() {
			gsap.to(this.$el.querySelector('.filters--cover'), { duration: 1, width: 0, ease: 'power4.out' });
			gsap.to(this.$el.querySelector('.filters--wrapper'), { duration: 1, x: '-100%', ease: 'power4.out' });
			gsap.to(this.$el, { duration: 0, delay: 1, width: 0 });
		},
		openFilters() {
			gsap.to(this.$el, { duration: 0, width: '100%' });
			gsap.to(this.$el.querySelector('.filters--cover'), { duration: 1, width: '100%', ease: 'power4.out' });
			gsap.to(this.$el.querySelector('.filters--wrapper'), { duration: 1, x: 0, ease: 'power4.out' });
		},
		requestData() {			
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/taxonomy/getlist', {"taxs" : this.taxonomies, "post_type": this.post_type})
				.then(response => {
					this.filters = response.data;
				});
    }
	},
	mounted() {
		//this.openFilters();
		this.requestData()
	}
}
</script>

<style lang="scss">
.products--filters {
	position: fixed;
	top: 0;
	left: 0;
	width: 0%;
	height: 100vh;
	z-index: 7;
	overflow: hidden;
	.filters--cover {
		position: absolute;
		width: 0%;
		height: 100%;
		background-color: rgba($white, 0.9);
	}
	.filters--wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 20vw;
		height: 100%;
		background-color: $white;
		border-right: 1px solid $black;
		overflow: hidden;
		transform: translateX(-100%);
		@include mobile {
			width: 70vw;
		}
		.cont {
			position: relative;
			width: 100%;
			padding: 80px 1vw 0 1vw;
			box-sizing: border-box;
			@include mobile {
				padding: 60px 5vw 0 5vw;
			}
			.button {
				position: relative;
				width: 100%;
				height: 40px;
				border-top: 1px solid $black;
				border-bottom: 1px solid $black;
				display: flex;
				justify-content: space-between;
				align-items: center;
				z-index: 6;
				box-sizing: border-box;
				span {
					font-family: 'Suisse Int';
					font-weight: 400;
					color: $black;
					font-size: 14px;
					line-height: 14px;
					margin: 0;
					text-transform: uppercase;
				}
			}
			.list {
				position: relative;
				width: 100%;
				display: flex;
				flex-direction: column;
				padding-top: 30px;
				.list--block {
					position: relative;
					width: 100%;
					margin-bottom: 30px;
					h2 {
						font-family: 'Suisse Int';
						font-weight: 400;
						color: $black;
						font-size: 14px;
						line-height: 14px;
						margin: 0 0 10px 0;
						text-transform: uppercase;
					}
					ul {
						position: relative;
						width: 100%;
						list-style: none;
						margin: 0;
						padding: 0;
						li {
							position: relative;
							display: flex;
							justify-content: space-between;
							align-items: center;
							span {
								font-family: 'Suisse Int';
								font-weight: 400;
								color: $black;
								font-size: 14px;
								line-height: 20px;
								margin: 0;
								text-transform: uppercase;
							}
							.check {
								position: relative;
								width: 16px;
								height: 16px;
								box-sizing: border-box;
								border: 1px solid $black;
								display: flex;
								justify-content: center;
								align-items: center;
								&:after {
									content: '';
									width: 12px;
									height: 12px;
									background-color: $black;
									opacity: 0;
									transition: opacity .35s ease-in-out 0s;
								}
								&.active {
									&:after {
										opacity: 1;
										transition: opacity .35s ease-in-out 0s;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>