<template>
	<div class="pagination--list">
		<div class="pagination--prev" v-if="current_page != 1" @click.prevent="changePage(current_page - 1)">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
		<ul v-if="total_pages > 1">
			<li v-for="n in total_pages" :key="n" :class="{ 'current' : (current_page == n)}" @click.prevent="changePage(n)">{{n}}</li>
		</ul>
		<div class="pagination--next" v-if="current_page != total_pages" @click.prevent="changePage(current_page + 1)">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			pending: false,
      error: false,
      data: null
		}
	},
	props: {
		current_page: {
			type: Number
		},
		posts_page: {
			type: Number
		},
		total_pages: {
			type: Number
		}
	},
	methods: {
		changePage(page){
			if(this.current_page != page){
				if(page > 1){
					this.$router.push('/' + this.$i18n.locale + '/news/' + page);
				}else{
					this.$router.push('/' + this.$i18n.locale + '/news/');
				}
			}
		}
	}
}
</script>

<style lang="scss">
.pagination--list {
	position: relative;
	width: auto;
	height: 30px;
	display: flex;
	.pagination--prev {
		position: relative;
		width: 30px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $black;
		svg {
			width: 20px;
			height: 20px;
			transform: rotate(180deg);
			fill: $black;
		}
	}
	ul {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		li {
			position: relative;
			width: 30px;
			height: 100%;
			border: 1px solid $black;
			text-align: center;
			line-height: 30px;
			font-family: 'Suisse Int';
			font-weight: 500;
			color: $black;
			font-size: 14px;
			&.current {
				background-color: $black;
				color: $white;
			}
		}
	}
	.pagination--next {
		position: relative;
		width: 30px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $black;
		svg {
			width: 20px;
			height: 20px;
			fill: $black;
		}
	}
}
</style>