<template>
	<div class="products">
		<ProductsHeader @toggle="toggleFilters" :isFiltersShowed="true" />
		<ProductsFilters :isOpen="openedFilters" @filtered="filterProducts" :finishFilter="finishFilter" />
		<div class="products--listing">
			<div class="listing--item" v-for="(product, index) in products" :key="product.id">
				<router-link :to="$i18nRoute({ name: 'Product', params: {slug: product.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
					<div class="item--wrapper">
						<div class="item--title">
							<span class="item--order">
								{{ getOrder((products_per_page * (current_page - 1) + (index + 1))) }}
							</span>
							<h2>{{ product.title.rendered }}</h2>
						</div>
						<div class="item--image">
							<ImageById v-if="product.featured_media" :media_id="product.featured_media"/>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		<div class="products--pagination">
			<ProductPagination :current_page="current_page" :posts_page="total" :total_pages="total_pages" v-if="total > 0" />
		</div>
	</div>
</template>

<script>
import ProductPagination from '@/components/ProductPagination';
import ProductsFilters from '@/components/ProductsFilters';
import ProductsHeader from '@/components/ProductsHeader';
import ImageById from '@/components/ImageById';
import axios from 'axios';
export default {
	data() {
		return {
			closeFiltersAni: 0,
			products: {},
			products_per_page: 20,
			current_page: parseInt(this.$route.params.page ? this.$route.params.page : 1),
			current_language : this.$route.params.locale,
			total: 0,
			total_pages: 1,
			openedFilters: false,
			isFiltered: false,
			finishFilter: false
		}
	},	
	metaInfo() {
		return{
			title: this.$t('head.title.products'),
			titleTemplate: '%s | Wearlight',
		}
  },
	components: {
		ProductPagination,
		ImageById,
		ProductsHeader,
		ProductsFilters
	},
	methods: {
		toggleFilters(val) {
			if(val){
				this.openedFilters = false
			}else{
				this.openedFilters = true
			}
		},
		getProducts() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?orderby=title&order=asc&per_page=' + this.products_per_page + '&page=' + this.current_page + '&lang=' + this.current_language)
				.then(response => {
					this.products = response.data;
					this.total = parseInt(response.headers['x-wp-total'])
					this.total_pages = parseInt(response.headers['x-wp-totalpages'])
				});
		},
		getFilteredProducts(filters) {
			this.$formLoaderVisible = true
			let str = '';
			if(filters.fondo.length > 0){
				str += '&fondo=' + filters.fondo.join(',');
			}
			if(filters.genere.length > 0){
				str += '&genere=' + filters.genere.join(',');
			}
			if(filters.lavorazione.length > 0){
				str += '&lavorazione=' + filters.lavorazione.join(',');
			}
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?orderby=title&order=asc&per_page=' + this.products_per_page + '&page=' + this.current_page + str)
				.then(response => {
					this.products = response.data;
					this.total = parseInt(response.headers['x-wp-total'])
					this.total_pages = parseInt(response.headers['x-wp-totalpages'])
					this.$formLoaderVisible = false
					this.finishFilter = true
					//console.log(this.products, this.total, this.total_pages)
				});
		},
		filterProducts(val){
			if(val){
				this.getFilteredProducts(val)
				this.isFiltered = val
				this.finishFilter = false
			}else{
				this.getProducts()
				this.isFiltered = false
			}
		},
		getOrder(ord) {
			if(ord <= 9){
				return '0' + ord
			}else{
				return ord
			}
		}
	},
	watch: {
		'$route.params.page': function(){
			this.current_page = parseInt(this.$route.params.page ? this.$route.params.page : 1)
			if(this.isFiltered){
				this.getFilteredProducts(this.isFiltered);
			}else{
				this.getProducts();
			}
		},		
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getProducts();
		}
	},
	beforeMount() {
		this.getProducts()
		//console.log(this.$route)
	},
	mounted() {
		let linkSmall = document.querySelectorAll('.link--mouse-small');
		[...linkSmall].forEach(el => {
			el.addEventListener('mouseenter', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.add('onLink--small')
			})
			el.addEventListener('mouseleave', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.remove('onLink--small')
			})
		});
	}
}
</script>

<style lang="scss">
.products {
	position: relative;
	padding: 120px 1vw 0 1vw;
	@include mobile {
		padding: 100px 5vw 0 5vw;
	}
	.products--listing {
		position: relative;
		width: 100%;
		height: auto;
		flex-wrap: wrap;
		overflow: hidden;
		display: flex;
		align-items: stretch;
		z-index: 5;
		.listing--item {
			position: relative;
			width: 25%;
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			box-sizing: border-box;
			padding: 1vw;
			@include mobile {
				width: 50%;
				&:nth-of-type(2n + 2) {
					border-right: 0
				}
			}
			a {
				position: relative;
				display: block;
				.item--wrapper {
					.item--title {
						position: relative;
						width: 100%;
						margin-bottom: 1vw;
						span {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 14px;
							line-height: 14px;
						}
						h2 {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 14px;
							line-height: 14px;
							margin: 0;
							text-transform: uppercase;
						}
					}
					.item--image {
						position: relative;
						width: 100%;
						img {
							width: 100%;
							height: auto;
							display: block;
						}
					}
				}
			}
			&:nth-of-type(4n + 4) {
				border-right: 0
			}
		}
	}
	.products--pagination {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 5vh 0;
	}
}
</style>