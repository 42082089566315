<template>
	<div class="news">
		<div class="posts--listing">
			<div class="listing--item" v-for="post in posts" :key="post.id">
				<div class="item--wrapper">
					<router-link :to="$i18nRoute({ name: 'Post', params: {slug: post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
						<div class="item--image">
							<ImageById :media_id="post.featured_media" />
						</div>
					</router-link>
					<div class="item--title">
						<h2 v-html="post.title.rendered"></h2>
						<span class="button">
							<router-link :to="$i18nRoute({ name: 'Post', params: {slug: post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
								{{$t('generale.read-more')}}
							</router-link>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="posts--pagination">
			<PostsPagination :current_page="current_page" :posts_page="total" :total_pages="total_pages" v-if="total > 0" />
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import PostsPagination from '@/components/PostsPagination';
import ImageById from '@/components/ImageById';
export default {
	data() {
		return {
			posts: {},
			posts_per_page: 3,
			current_page: parseInt(this.$route.params.page ? this.$route.params.page : 1),
			post_category: [42],
			total: 0,
			total_pages: 1,
			current_language: this.$route.params.locale
		}
	},
	components: {
		PostsPagination,
		ImageById
	},
	metaInfo() {
		return{
			title: this.$t('head.title.news'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		getPosts() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/posts/?per_page=' + this.posts_per_page + '&page=' + this.current_page + '&categories=' + this.post_category + '&lang=' + this.current_language)
				.then(response => {
					this.posts = response.data;
					this.total = parseInt(response.headers['x-wp-total'])
					this.total_pages = parseInt(response.headers['x-wp-totalpages'])
				});
		},
	},
	watch: {
		'$route.params.page': function(){
			this.current_page = parseInt(this.$route.params.page ? this.$route.params.page : 1)
			this.getPosts()
		},
		'$route.params.locale': function(){
			this.current_page = parseInt(this.$route.params.page ? this.$route.params.page : 1)
			this.current_language = this.$route.params.locale
			this.getPosts()
		}
	},
	beforeMount() {
		this.getPosts()
	}
}
</script>

<style lang="scss">
.news {
	position: relative;
	padding: 120px 1vw 0 1vw;
	@include mobile {
		padding: 60px 5vw 0 5vw;
	}
	.posts--listing {
		position: relative;
		width: 100%;
		height: auto;
		flex-wrap: wrap;
		overflow: hidden;
		display: flex;
		align-items: stretch;
		z-index: 5;
		.listing--item {
			position: relative;
			width: 100%;
			border-bottom: 1px solid $black;
			box-sizing: border-box;
			padding: 1vw 0;
			@include mobile {
				padding: 5vw 0;
			}
			.item--wrapper {
				position: relative;
				width: 100%;
				height: auto;
				display: flex;
				align-items: stretch;
				@include mobile {
					flex-direction: column;
					align-items: center;
				}
				a {
					position: relative;
					display: block;
					.item--image {
						position: relative;
						width: 40vw;
						@include mobile {
							width: 100%;
						}
						img {
							position: relative;
							width: 100%;
							height: auto;
							display: block;
						}
					}
				}
				.item--title {
					position: relative;
					width: calc(100% - 40vw);
					height: auto;
					padding-left: 1vw;
					@include mobile {
						padding-left: 0;
						padding-top: 5vw;
						width: 100%;
					}
					h2 {
						text-transform: uppercase;
						font-family: 'Saol Display';
						font-size: 4vw;
						line-height: 4vw;
						font-weight: 400;
						color: $black;
						margin: 0;
						@include mobile {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 5vw;
							line-height: 5vw;
							text-transform: uppercase;
						}
					}
					.button {
						position: relative;
						width: auto;
						background-color: transparent;
						border-radius: 50px;
						text-align: center;
						border: 1px solid $black;
						display: inline-block;
						margin-top: 5vh;
						@include mobile {
							margin-top: 5vw;
						}
						a {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 12px;
							line-height: 12px;
							text-transform: uppercase;
							padding: 15px 30px;
						}
					}
				}
			}
			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
	.posts--pagination {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 5vh 0;
	}
}
</style>